import Cookies from 'js-cookie';
import type { ComputedRef, Ref } from 'vue';
import { useState } from '#app';

interface UseConsent {
    hasResponded: ComputedRef<boolean>;
    hasConsent: ComputedRef<boolean>;
    setConsent: (hasConsent: boolean) => void;
    preferences: Ref<undefined | HTMLDialogElement>;
}

export default function useConsent(): UseConsent {
    const responded = useState('responded', () => !!import.meta.client && !!Cookies.get('cookie-consent'));
    const consent = useState('consent', () => responded.value ? Cookies.get('cookie-consent') === 'true' : true);
    const preferences = useState<undefined | HTMLDialogElement>('preferences', () => undefined);
    // todo update package
    const { setConsent: gSetConsent } = useGtag();

    const setConsent = (hasConsent: boolean) => {
        const expires = new Date();

        if (hasConsent) {
            expires.setFullYear(expires.getFullYear() + 1);
        } else {
            expires.setMonth(expires.getMonth() + 6);
        }

        // this works by setting a variable on the window object
        gSetConsent({ hasConsent });

        responded.value = true;
        Cookies.set('cookie-consent', String(hasConsent), {
            expires,
            value: String(hasConsent),
            path: '/',
            sameSite: 'strict'
        });
    };

    return {
        hasResponded: computed(() => responded.value),
        hasConsent: computed(() => consent.value),
        setConsent,
        preferences
    };
}
